//turns amount of milliseconds into string
//like 7 days 1 hour 3 minutes.
export function timeString(t){
  let answer = '';
  if( t < 0){
    t = -t;
    answer = 'negative '
  }
  let unitNames  = [ 'millisecond', 'second', 'minute', 'hour', 'day', 'year'];
  let relative   = [          1000,       60,       60,     24,   365,      1];
  let macroUnit2InputUnit     = 31536000000;
  let seperator = ', '
  let maxUnitsAdded = 3;
  let unitsAdded = 0;
 
  let numberOfRecord = 0;
  let workingNumber = t / macroUnit2InputUnit;
  for( let checkUnitMag = unitNames.length - 1 ; checkUnitMag >= 0 && unitsAdded < maxUnitsAdded; checkUnitMag -- ){
    workingNumber = workingNumber * relative[ checkUnitMag];
    if(workingNumber > 1  || unitsAdded > 0 || checkUnitMag === 0){
      if( checkUnitMag === 0 || unitsAdded + 1 === maxUnitsAdded){
        numberOfRecord = Math.round( workingNumber)
        if( numberOfRecord === 0 && unitsAdded === 0 && checkUnitMag === 0){
          return 'less than a' + unitNames[0];
        }
      }else{
        numberOfRecord = Math.trunc(workingNumber);
        workingNumber  = workingNumber - numberOfRecord;
      }
      let stringUnit = ( unitsAdded === 0 ? '' : seperator) + numberOfRecord + ' ' + unitNames[ checkUnitMag]
      + ( numberOfRecord === 1 ? '' : 's');
      answer = answer + stringUnit;
      unitsAdded ++;
    }
  }
  return answer;
}

function timeGenerator(ms, s, min, hour, day, year ){
 let t = ( year * 31536000000) + (day * 86400000) +(hour * 3600000) + (min * 60000) + (s * 1000) + ms;
  return t;
}