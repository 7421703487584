import {useEffect, useState} from "react";
import {Card} from './card.js';
export function AllData(){
  
  // const ctx =useContext(UserContext);
  const [contactStatus,  setContactStatus]   = useState('No attempt to Contact DB has been made - press button');
  const [contactedDt,    setContactedDt]     = useState(null);
  const [userDataCopy,   setUserDataCopy]    = useState('');
  const [histDataCopy,   setHistDataCopy]    = useState('');
  const [userDataHeader, setUserDataHeader]  = useState('');
  const [histDataHeader, setHistDataHeader]  = useState('');

  // const []
  function timeDateString(now){
    let hours = now.getHours();
    const minutes = now.getMinutes();
    const month = now.toLocaleString('default', { month: 'long' });
    const day = now.getDate();
    const year = now.getFullYear();

    let ampm = 'AM';
    if (hours >= 12) {
      ampm = 'PM';
    }
    if (hours > 12) {
     hours -= 12;
    }

    const nowString = `${hours}:${minutes.toString().padStart(2, '0')} ${ampm} ${day} ${month} ${year}`;
    console.log(nowString);
    return nowString;
  }
  
  
  function getUserData(){
    //fetch all data from API 
    fetch('/account/all')
    .then(response => response.json())
    .then(data => {
      let now = new Date();
      if( typeof data === 'object'  && data.userData &&  data.histData){
        //DB successfully contacted
        
        
          let userHeaderChange = '';
          let histHeaderChange = '';
          let updateSignalU    = false;
          let updateSignalH    = false;
          let userDataNew = data.userData;
          let juserDataNew = JSON.stringify( userDataNew);
          let juserDataOld = JSON.stringify( userDataCopy);
          let histDataNew = data.histData;
          let jhistDataNew = JSON.stringify( histDataNew);
          let jhistDataOld = JSON.stringify( histDataCopy);

          if( juserDataNew === ''){ userHeaderChange = 'Error: No User Data Received'}
            else{
              if( juserDataNew === juserDataOld){
                userHeaderChange = 'Users data collection has not changed';
              } else {
                userHeaderChange = 'Users data collection updated';
                updateSignalU    = true;
              }
            }

            if( jhistDataNew === ''){ histHeaderChange = 'Error: No History Data Received'}
            else{
              if( jhistDataNew === jhistDataOld){
                histHeaderChange = 'History data collection has not changed';
              } else {
                histHeaderChange = 'History data collection updated';
                updateSignalH    = true;
              }
            }
          
          setUserDataHeader( userHeaderChange);
          setHistDataHeader( histHeaderChange);
          if(updateSignalU) setUserDataCopy( userDataNew);
          if(updateSignalH) setHistDataCopy( histDataNew);
          setContactStatus( 'Success Contacting DB: ' + timeDateString(now));
          setContactedDt( now);
        }else{
          //error contacting DB
          let ReceivedU = userDataCopy ? 'Received' : 'Gotten';
          let ReceivedH = histDataCopy ? 'Received' : 'Gotten'; 

          setUserDataHeader( 'Error: No User Data ' + ReceivedU);
          setHistDataHeader( 'Error: No History Data ' + ReceivedH);
          setContactStatus( 'Error Contacting DB: ' + timeDateString(now));
          setContactedDt( now);

        }

      });
  }
  


    
    return (
      <div className=" d-flex flex-column w-100">

      <Card 
        bgcolor="light"
        otherClasses = "w-100"
        txtcolor="dark"
        header="All Data"
        title={userDataCopy || histDataCopy ? "Data currently stored at cloud.mongodb.com" : "Haven't yet connected to MongoDB"}
        maximize = "true"
        text=""
        body={
          (<>
            <h4>{contactStatus}</h4>
            <h5>{userDataHeader}</h5>
            <h5>{histDataHeader}</h5>
            <button className={"btn btn-outline-secondary"} onClick  ={getUserData}>
                                  get/update all user data from database
                                </button> 
            <h5>Users Collection: </h5>                    
            <h6>
              {JSON.stringify(userDataCopy)}
            </h6>
            <h5>History Collection: </h5>                    
            <h6>
              {JSON.stringify(histDataCopy)}
            </h6>



          </>)
          } 
          
          />
          </div>
    );
  }


//  Code from version without server
//   //creates row of info from user object
//   const userInfo = (user, index, color1, color2) => {
//     let userID = user.userID;
//     let name = user.name;
//     let email = user.email;
//     let password = user.password;
//     let balance = user.balance;
//     let color = index % 2;
//     let colorClass = color === 0 ?  'btn-' + color1 : 'btn-' + color2 ;

//     //return for user Info
//     return(
//       <>
//     <div className={"btn-group " + colorClass} role="group" aria-label="userinfo" key={index}>
//       <button type="button" className={"btn " + colorClass}  >{'userID: '   + userID}   </button>
//       <button type="button" className={"btn " + colorClass}  >{'name: '     + name }    </button>
//       <button type="button" className={"btn " + colorClass}  >{'email: '    + email }   </button>
//       <button type="button" className={"btn " + colorClass}  >{'password: ' + password }</button>
//       <button type="button" className={"btn " + colorClass}  >{'balance: '  + balance } </button>
    
//     </div>
//     <br/>
//       </>
//       // <li>{'userID: ' + userID + '  name: ' + name + '  email: ' + email + '  password: ' + password } </li>
//     )
//   }
// ////end userInfo

  
//   let users = ctx.users;
//   const userList = users.map( (userObj, index) =>
//   userInfo(userObj, index, 'light', 'secondary')
//   );
  
  
  
  
//   let activeUser = ctx.activeUser;
//   const aUCardBody = activeUser === null ? (
//     <div className="btn-group btn-danger" role="group" aria-label="activeUser" index={6} key={7}>
//       <button type="button" className="btn btn-danger" key={6} index = {7}>No one is currently logged in. </button>
      
//     </div>
//   ):(
    
//     userInfo( activeUser, 200, 'success', 'success')
//     )
    

//   //creates row of info user.history
// const actionInfo = (event, index) =>{
//   if(event === undefined) return;
//   let amount  = '';
//   let balance = '';
//   let userID = event.userID;
//   let type = event.type;
//   let data = event.data;
//   if( data !== null){
//     if( 'amount' in data)  amount = data.amount;
//     if( 'balance' in data) balance = data.balance;
//   }
//   let color = 'primary'
//   let typedescr = 'unrecognized event type';
//   switch (type){
//     case 'login':        
//       color = 'warning';
//       typedescr = 'Login'
//       break;
//     case 'newUser':
//        color = 'dark';
//        typedescr = 'New User Created'
//        break;
//     case 'deposit':
//        color = 'success';
//        typedescr = 'Deposit';
//        break;
//     case 'withdraw':
//        color = 'danger';
//        typedescr = 'Withdrawal';
//        break;
//     case 'balanceShown': 
//        color = 'info';
//        typedescr = 'Balance Inquiry' 
//        break;   
//       }
//       let colorClass = 'btn-' + color;
//    //return for actionInfo
//    return(
//     <>
//      <div className={"btn-group " + colorClass} role="group" aria-label="userinfo" key={index + 100}>
//      <button type="button" className={"btn " + colorClass} key={6} >{typedescr + '=>' }</button>
//      <button type="button" className={"btn " + colorClass} key={7} >{'userID: ' + userID} </button>
//     { amount !== '' && 
//     <button type="button" className={"btn " + colorClass} key={8} >{'Amount: ' + amount }</button>
//   }
//     { balance !== '' && 
//     <button type="button" className={"btn " + colorClass} key={9} >{'Balance: ' + balance }</button>
//   }
//   </div>
//   <br/>
//   </>
//     // <li>{'userID: ' + userID + '  name: ' + name + '  email: ' + email + '  password: ' + password } </li>
//     )
//   }
//   ////end actionInfo 
    
//   let history = ctx.history;
//   const hisList = history.map( (hisObj, index) =>
//     actionInfo( hisObj, index)
//   );
    
    
    
    
    
    
//   //  return for AllData 
//     return (
      
//       <>
//       {/* bootstrap button example */}
//       {/* {}
//     <div className="btn-group bg-danger" role="group" aria-label="Basic example">
//     <button type="button" className="btn btn-secondary">Left</button>
//     <button type="button" className="btn btn-secondary">Middle</button>
//     <button type="button" className="btn btn-secondary">Right</button>
//     </div>
//   <br/> */}
      
    
    
    
      
    
//       <Card 
//       bgcolor="primary"
//       txtcolor="white"
//       header=""
//       title="Active User:"
//       text=""
//       maximize = {true}
//       body={
//         (<>
//           {aUCardBody}
        
//         </> )
//         } 
        
//         />
    
//     <Card 
//       bgcolor="primary"
//       txtcolor="white"
//       header=""
//       title="User Information"
//       text=""
//       maximize = {true}
//       body={
//         (<>
//           {userList}
        
//         </> )
//         } 
        
//         />

//     <Card 
//       bgcolor="primary"
//       txtcolor="white"
//       header=""
//       title="User History"
//       text=""
//       maximize = {true}
//       body={
//         (<>
//           {hisList}
        
//         </> )
//         } 
        
//         />

        
    
    
    
    
//     {/* <div>{JSON.stringify(ctx)}</div> */}
//     {/* <div>ctx.history</div> */}
//     {/* <h2>ctx.history[0]:</h2>
//     <div>{JSON.stringify(ctx.history[0])}</div>
//     <h2>ctx.history[1]:</h2>
//     <div>{JSON.stringify(ctx.history[1])}</div>
//     <h2>ctx.history[2]:</h2>
//     <div>{JSON.stringify(ctx.history[2])}</div> */}
    
//     {/* {ctx.history.length > 0 && <div>{actionInfo(ctx.history[0])}</div>} */}
    
//     {/* {actionInfo(ctx.history[0],9)} */}
    
//     </>
    
    
    
    
    
    
    
    
//     )
//   // end of all data  
//   }