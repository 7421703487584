import { dollarFormat } from "./dollarFormat";
import { formatDate }   from "./formatDate";

  
//formatTable should default to a falsy value if not given
//props.tableFormat.fieldArray provides the fields to be displayed and which order, defaults to all fields in some order
//z identifies between multiple tables or tools on page, to help especially with determining click location
//TableData expects an array of objects with same fields.
export function Table(props){
  let section = 'all';
  //console.log('Table - props:', props);
  let tableData   = props?.tableData??false;
  let tableFormat = props?.tableFormat??false;
  let fieldArray = (props?.fieldArray??false) || Object.keys( tableData[0]);
  let newDollarFormat = props?.tableFormat?.newDollarFormat??((x) => {return dollarFormat(x,true,true)});
  //console.log( 'fieldArray: '+ fieldArray)
  let addClasses = tableFormat?.all?.classNames??'';
  let z = props?.z??0;
  let positionX = [0,0,z];
  return (
    <table className={"table " + addClasses} >
      <TableHeader                     fieldArray={fieldArray} tableFormat = {tableFormat} z = {z}/>
      <TableBody tableData={tableData} fieldArray={fieldArray} tableFormat = {tableFormat} z={z}/>
      <TableFoot tableData={tableData}/>
    </table>
  );
  
  function TableHeader(props) {
    let fieldArray  = props.fieldArray;
    let tableFormat = props.tableFormat;
    let z = props?.z??0;
    let addClasses = tableFormat?.header?.classNames??false;
    let aCTHead = addClasses?.thead??'';
    return (
      <thead className={aCTHead}>
      <tr>
        <th cellposition = {[0,0,z]} scope="col">#</th>
        {fieldArray.map((field,index) => {
          return <TableCellHeader key={'h-' + index} field={field} cellposition = {[ index + 1 , 0, z] } tableFormat = {tableFormat}/>
        })}
      </tr>
    </thead>
  )
}
//bootstrap text alignment classes: text-start, text-center, text-end
function TableCellHeader(props){
  let tableFormat  = props.tableFormat;
  let field        = props.field;
  let title = field;
  let cellposition = props.cellposition;
  let addClass1     = tableFormat?.headerCell?.classNames??'';
  let byfield   = tableFormat?.headerCell?.byfield??{};
  let addClass2 = '';
  if(byfield.hasOwnProperty(field)) addClass2 = byfield[field];
  let addClasses = addClass1 + ' ' + addClass2;
  let psuedonym   = tableFormat?.headerCell?.psuedonym??{};
  if(psuedonym.hasOwnProperty(field)) title = psuedonym[field];
  
  return(<th className={addClasses} scope="col">{title}</th>);
}
function TableBody(props){ 
  let tableData = props.tableData;
  let tableFormat = props.tableFormat;
  let fieldArray = props.fieldArray;
  let addClasses = tableFormat?.tableBody?.classNames??'';
  let z  = props.z; 
  
  return (
    <tbody className= {addClasses}>   
      {tableData.map((rowData , index) => {
        return <TableRow y={index + 1} z={z} key={'Row-' + index} rowData={rowData} fieldArray={fieldArray} tableFormat={tableFormat}/>
      })}   
    </tbody>
  )
}
function TableRow(props){
  let tableFormat = props.tableFormat;
  let rowData     = props.rowData;
  let fieldArray  = props.fieldArray;
  let y           = props.y;
  let z           = props.z;
  let highlightEmail = tableFormat?.row?.loginEmail??false;
  let maxBal      = tableFormat?.row?.maxBal??false; 
  let minBal      = tableFormat?.row?.minBal??false; 
  let classAdd1   = tableFormat?.row?.classNames??''
  let formatFunction = tableFormat?.row?.formatFunction??false;
  let highlightAll = tableFormat?.row?.highlightAll??false;
  let hightlightYellow = false;
  let highlightRed = false;
  let highlightgreen = false;
  let classAdd2='';
  let inputObject = { rowData, fieldArray, y, z, tableFormat};
  if( formatFunction){
    classAdd2 = formatFunction(inputObject);
  }
  let classAdd3 = '';
  let halfColor = '';
  if(maxBal && (rowData?.balance??false) && rowData.balance === maxBal ){
    classAdd3 = 'table-success';
  }
  if(minBal && (rowData?.balance??false) && rowData.balance === minBal ){
     classAdd3 = 'table-danger';
  }
  if( highlightAll ||  (highlightEmail && (rowData?.email??false) && rowData.email === highlightEmail )){ 
      //in case login is max or min and both highlights called
      if( classAdd3) halfColor = classAdd3;
      classAdd3 = 'table-warning';
  }


  let classAdd = classAdd1 +' '+ classAdd2 + ' ' + classAdd3;
  // if( rowData['balance'] < 0) {  
    //    classAdd = classAdd + ' table-danger';
    // } 
    //handling highlighting tools from banker page
    return(
      <tr  className = {classAdd}  >
      <th scope="row">{y} </th>
      {fieldArray.map( (datumKey, index)=>{
        return <TableCell key={'x-' + (index + 1) + ' y-' + y + 'z-' + z} datum={rowData[datumKey]} fieldName = {datumKey} rowData={rowData}
         halfColor = {halfColor} cellposition = {[ index + 1, y, z]} tableFormat={tableFormat}/>
      })}
    </tr>
  )
}
//bootstrap text alignment classes: text-start, text-center, text-end
// defining Header column
function TableCell(props){
  let datum        = props.datum;
  let fieldName    = props.fieldName;
  let cellposition = props.cellposition;
  let tableFormat  = props.tableFormat;
  let rowData      = props.rowData;
  let evenField    = cellposition[0] % 2;
  let halfColor = props.halfColor;
  if( evenField) halfColor = '';
  let formatFunctionClassNames = tableFormat?.tableCell?.classNamesFunction??false;
  let formatFunctionText       = tableFormat?.tableCell?.textFunction??false;
  let inputObject1             = { value:datum, field:fieldName, cellposition, rowData, tableFormat, dollarFormat:dollarFormat, formatDate:formatDate};
  let inputObject2             = { value:datum, field:fieldName, cellposition, rowData, tableFormat, dollarFormat:dollarFormat, formatDate:formatDate};

  let value = datum;
  let classNames = '';
  if( formatFunctionClassNames) classNames = formatFunctionClassNames( inputObject1);
  if( formatFunctionText)            value = formatFunctionText(       inputObject2);
  
  
 
  

  return(<td className={classNames + ' ' + halfColor}>{value}</td>);
}

function TableFoot(props){
  let tableData = props.tableData
  let accountTotal = tableData.map( ( account) => account.balance).reduce((total, num) =>  total + num , 0);
  accountTotal = newDollarFormat( accountTotal)
  return (
    <tfoot>
      <tr>
        <td colSpan="5"></td>
        <td className="text-end">{accountTotal}</td>
      </tr>
    </tfoot>
  );
}


}
 

