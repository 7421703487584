import { useState, useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from './index.js';
import { Card } from './card.js';

export function Roles() {
  const [rolesDisplay, setRolesDisplay] = useState(false);
  const [dBContactDT,  setDBContactDT]  = useState('');
  const ctx = useContext(UserContext);

  //these roles can't be added or subtracted
  let disabledRoles = ['isDrWho'];
  //these roles hidden, _id is used by mongodb but doesn't represent a role
  let hiddenRoles = ['_id'];
  //rehides DT display when user navigates away
  let hideChangeDT = true;
  let rolesOriginal = ctx.activeUser?.role??{};

  useEffect(() => {
    console.log('resetting Roles Display')
    resetDisplay();
  }, []);

  function resetDisplay() {
    let newRolesDisplay = { ...rolesOriginal };
    setRolesDisplay(newRolesDisplay);
  }
  

  function applyRoleChanges() {
    //contacting DB
    let stringRoles = JSON.stringify(rolesDisplay);
    console.log('ChangedRoles: ', stringRoles);
    let email = ctx.activeUser.email;
    let password = ctx.activeUser.password;

      const url = '/account/roleChange/' + email + '/' + password + '/' + stringRoles  ;
      //first balance set by default value in ./setupDB/schema.js
      (async () => {
        var res  = await fetch(url);
        var data = await res.json();
        console.log(data);
      })();
      
    //changing values stored in client
    ctx.activeUser.role = { ...rolesDisplay };
    //changing Dt of last update
    let now = new Date();
    ctx.status.rolesChangedDT = now;
    //triggering rerender
    setDBContactDT( now);
    let reRenderNav = ctx.tools.updateNavbar;   
    reRenderNav();
  }
  function timeDateString(now) {
    let hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    const month = now.toLocaleString('default', { month: 'long' });
    const day = now.getDate();
    const year = now.getFullYear();
  
    let ampm = 'AM';
    if (hours >= 12) {
      ampm = 'PM';
    }
    if (hours > 12) {
      hours -= 12;
    }
  
    const dateString = `${day} ${month} ${year}`;
    const timeString = `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')} ${ampm}`;
    console.log(`${timeString}\n${dateString}`);
    return `${timeString}\n${dateString}`;
  }
  

  function didRolesChange() {
    return JSON.stringify(rolesDisplay) !== JSON.stringify(rolesOriginal);
  }

  let applyButtonActive = didRolesChange();
  
  let applyButtonText = applyButtonActive ? 'Apply role changes' : 'No changes to Apply';
  let dbText = '';
  let dt = '';

  if(hideChangeDT){
    dt = dBContactDT;
  }else{
    if (ctx.status?.rolesChangedDT??false) dt = ctx.status.rolesChangedDT;
  }
  
  
  if( dt){
    dbText = ` Roles Updated - ${timeDateString(dt)}`
  } 
    

  function toggleRole(roleName) {
    let value = rolesDisplay?.[roleName] ?? false;
    let newValue = !value;
    let newObject = { ...rolesDisplay, ...{ [roleName]: newValue } };
    setRolesDisplay(newObject);
  }
  return (
    <>
      <Card
        bgcolor="info"
        header="Create Account"
        status={''}
        statusTxtColor="danger"
        body={
          <div>
            {/* <h4>{JSON.stringify(rolesDisplay)}</h4>


            <div key={'keytest'}>
                    <div className="form-check form-switch">
                      <input
                        // className={`form-check-input${rolesDisplay?.[key] ? ' checked' : ''}${disabledRoles.includes(key) ? ' disabled' : ''}`}
                        className={`form-check-input  disabled `} checked={true} disabled = {true}
                        type="checkbox"
                        // onChange={() => toggleRole(key)}
                        id="flexSwitchCheckCheckedDisabled"
                      />
                      <label className="form-check-label" htmlFor="flexSwitchCheckCheckedDisabled">
                        Test Job
                      </label>
                    </div>
                  </div> */}


            {Object.keys(rolesDisplay).map(key => {
              if (!hiddenRoles.includes(key)) {
                return (
                  <div key={key}>
                    <div className="form-check form-switch">
                      <input
                        className={"form-check-input "}
                        checked={ rolesDisplay?.[key]??false}
                        disabled={ disabledRoles.includes(key)}
                        type="checkbox"
                        onChange={() => toggleRole(key)}
                        id="flexSwitchCheckCheckedDisabled"
                      />
                      <label className="form-check-label" htmlFor="flexSwitchCheckCheckedDisabled" title= {disabledRoles.includes(key)?'Role not yet available':null}>
                        {key}
                      </label>
                    </div>
                  </div>
                );
              }
              return null;
            })}
            <button className = "btn btn-warning"
                     onClick  = { applyRoleChanges }
                     disabled = { !applyButtonActive }>
              <h5>{applyButtonText}</h5>
            </button> 
            <h5>{dbText}</h5>
          </div>
        }
      />
    </>
  );
}


  // function handleCreate(){
  //   console.log(nameT,emailT,passwordT);
    
  //   if( !validate(nameT, 'name')){         return;}
  //   if( !validate(emailT, 'email')){       return;}
  //   if( !validate(passwordT, 'password')){ return;}
  //   if( passwordT.length < 8){
  //     setStatusX('Error: password must be at least 8 characters');
  //     clearTimeout(timeoutID);
  //     setTimeoutID(setTimeout( ()=> setStatusX(''), 3000));
  //     return;
  //   }
  //   const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   console.log('testing Email Format');
  //   console.log('emailT: ', emailT);
  //   console.log('emailRegex.test(emailT): ', emailRegex.test(emailT));
  //   console.log('emailRegex: ', emailRegex);
    
  //   if(! emailRegex.test(emailT)){
  //     setStatusX('Unrecognized Email Format: expected Somthing @ Something . at least 3 letters');
  //     clearTimeout(timeoutID);
  //     setTimeoutID(setTimeout( ()=> setStatusX(''), 3000));
  //     return;
  //   }
  
  //   // let userID = ctx.users.length;
  //   // ctx.users.push( { userID,nameT, emailT,passwordT,balance: 100} )
  //   //ctx.history.push( 'New Account Created:  Name = ' + nameT + ' Email = ' + emailT + ' password = ' + passwordT);
  //   console.log('New Account: ', nameT, '-', emailT)
  //   const url = '/account/create/' + nameT +'/' + emailT +'/' +passwordT;
  //   //first balance set by default value in ./setupDB/schema.js
  //   (async () => {
  //     var res  = await fetch(url);
  //     var data = await res.json();
  //     console.log(data);
  //   })();
  //   // let type = 'newUser';
  //   // let data = null;
  //   // // ctx.history.push({userID, type, data});
    
  //   //  type = 'deposit';
  //   //  data = {
  //   //   amount:  100,
  //   //   balance: 100           
  //   // };
  //   // ctx.history.push({userID, type, data});
  //   // //logging out at user creation
  //   // ctx.activeUser = null;
  //   setName(nameT);
  //   setEmail(emailT);
  //   setShow(false);
  // }