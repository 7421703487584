import {useEffect, useState, useContext, useRef} from "react";
import { UserContext} from './index.js';
import {Card} from './card.js';
import {Table} from './table.js'
import { formatDate } from "./formatDate.js";
import { dollarFormat, currencyFormat ,getCountryList, dollarSymbolByCountry, currencyInfoByCountry } from "./dollarFormat.js";
import { Col, Row } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
export function Banker(){
  
  const ctx = useContext(UserContext);
  const [contactStatus,             setContactStatus]                      = useState('No attempt to Contact DB has been made - press button');
  const [contactedDt,               setContactedDt]                        = useState(null);
  const [userDataCopy,              setUserDataCopy]                       = useState('');
  const [histDataCopy,              setHistDataCopy]                       = useState('');
  const [userDataHeader,            setUserDataHeader]                     = useState('');
  const [histDataHeader,            setHistDataHeader]                     = useState('');
  const [showRawUserData,           setShowRawUserData]                    = useState(false);
  const [showRawHistData,           setShowRawHistData]                    = useState(false);
  const [minimizeFetchInfo,         setMinimizeFetchInfo]                  = useState(true);
  const [renderTriggerUserExpand,   setRenderTriggerUserExpand]            =useState( false);
  const [renderTriggerHistExpand,   setRenderTriggerHistExpand]            =useState( false);
  const [countryChosen,             setCountryChosen]                      =useState( []);
  const [accountsFormatType,        setAccountsFormatType]                 =useState('minimize');
  const [accountsFormatSortType,    setAccountsFormatSortType]             =useState('balance');
  const [accountsFormatSortReverse, setAccountsFormatSortReverse]          =useState(true);
  const [ highlightMinAccount,      setHighlightMinAccount]                =useState(false);
  const [ highlightMaxAccount,      setHighlightMaxAccount]                =useState(false);
  const [ highlightYourAccount,      setHighlightYourAccount]                =useState(true);
  const rawhistdataRef  = useRef(null);
  let toggleUserData    = () => setShowRawUserData(!showRawUserData);
  let toggleHistData    = () => setShowRawHistData(!showRawHistData);
  let toggleMinFetch    = () => setMinimizeFetchInfo(!minimizeFetchInfo);
  let renderUserExpand  = () => setRenderTriggerUserExpand(!renderTriggerUserExpand)
  let renderHistExpand  = () => setRenderTriggerHistExpand(!renderTriggerHistExpand)

  //scrolling to top of Raw Hist Data so user can be sure to see button's effect
  useEffect(() => {
    if (showRawHistData && rawhistdataRef.current) {
      rawhistdataRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [ rawhistdataRef.current, showRawHistData]);
  //setting dollar symbol
  let ds = '$';
  let newDollarFormat = currencyFormat;
  if( !(typeof countryChosen === 'object') ){
    ds = dollarSymbolByCountry(countryChosen);
  }
  newDollarFormat = (input) => currencyFormat( input, ds);
  
  let countryList = getCountryList();
  countryList.sort();
  function CountrySelector() {
  
    function handleSelect(selected) {
      if( countryList.includes(selected[0]))
      setCountryChosen(selected[0]);
    }
    
    return (
      Array.isArray(countryChosen) ? (<>
      <Typeahead
      className="bg-info text-info"
      id="chooseCountryToSelectCurrencyMenu"
      options={countryList}
      placeholder="Type a country name..."
      onChange={handleSelect}
      selected={countryChosen}
      
      />
      </>)
       : (<>
      {/* {/* <h6> {countryChosen}</h6> */}
    {!(typeof countryChosen === 'object') &&<h6> { 'currency -'+ currencyInfo.currency + '    currency symbol - \'' + currencyInfo.dollarSign + '\''}</h6> }
    
      <br/>
      <button className="btn btn-info" onClick={()=>{setCountryChosen([])}}>Select a different currency</button>
      </>)

    
    );
  }

  function timeDateString(input){
    let nowX = new Date(input)
    let hours = nowX.getHours();
    const minutes = nowX.getMinutes();
    const month = nowX.toLocaleString('default', { month: 'long' });
    const day = nowX.getDate();
    const year = nowX.getFullYear();

    let ampm = 'AM';
    if (hours >= 12) {
      ampm = 'PM';
    }
    if (hours > 12) {
     hours -= 12;
    }

    const nowString = `${hours}:${minutes.toString().padStart(2, '0')} ${ampm} ${day} ${month} ${year}`;
    console.log(nowString);
    return nowString;
  }
  useEffect( getUserData,[]);
  function collapseAllUserRaw(){
    ctx.status.bankerRawExpand = ctx.status.bankerRawExpand.filter( ( string) => !(string.startsWith( 'user')));
    renderUserExpand();
  }
  function collapseAllHistRaw(){
    ctx.status.bankerRawExpand = ctx.status.bankerRawExpand.filter( ( string) => !(string.startsWith( 'hist')));
    renderHistExpand();
  }
  
  function getUserData(){
    //fetch all data from API 
    fetch('/account/all')
    .then(response => response.json())
    .then(data => {
      let now = new Date();
      if( typeof data === 'object'  && data.userData &&  data.histData){
        //DB successfully contacted
        
        
          let userHeaderChange = '';
          let histHeaderChange = '';
          let updateSignalU    = false;
          let updateSignalH    = false;
          let userDataNew = data.userData;
          let juserDataNew = JSON.stringify( userDataNew);
          let juserDataOld = JSON.stringify( userDataCopy);
          let histDataNew = data.histData;
          let jhistDataNew = JSON.stringify( histDataNew);
          let jhistDataOld = JSON.stringify( histDataCopy);

          if( juserDataNew === ''){ userHeaderChange = 'Error: No User Data Received'}
            else{
              if( juserDataNew === juserDataOld){
                userHeaderChange = 'Users data collection has not changed';
              } else {
                userHeaderChange = 'Users data collection updated';
                updateSignalU    = true;
              }
            }

            if( jhistDataNew === ''){ histHeaderChange = 'Error: No History Data Received'}
            else{
              if( jhistDataNew === jhistDataOld){
                histHeaderChange = 'History data collection has not changed';
              } else {
                histHeaderChange = 'History data collection updated';
                updateSignalH    = true;
              }
            }
          
          setUserDataHeader( userHeaderChange);
          setHistDataHeader( histHeaderChange);
          if(updateSignalU) setUserDataCopy( userDataNew);
          if(updateSignalH) setHistDataCopy( histDataNew);
          setContactStatus( 'Success Contacting DB: ' + timeDateString(now));
          setContactedDt( now);
        }else{
          //error contacting DB
          let ReceivedU = userDataCopy ? 'Received' : 'Gotten';
          let ReceivedH = histDataCopy ? 'Received' : 'Gotten'; 

          setUserDataHeader( 'Error: No User Data ' + ReceivedU);
          setHistDataHeader( 'Error: No History Data ' + ReceivedH);
          setContactStatus( 'Error Contacting DB: ' + timeDateString(now));
          setContactedDt( now);

        }

      });
  }
 


  //test accounts table
// //Table Component props = tableData - an array of objects, tableFormat.fieldArray order of fields to be dislplayed, 
// let tableDATA = JSON.parse(dataX)
// console.log('dataX',dataX)
let tableFormat = {
  all: {}
};
tableFormat.all.classNames = 'table-primary table-striped table-bordered';
tableFormat.headerCell = {};
tableFormat.headerCell.psuedonym = {};
tableFormat.headerCell.psuedonym["createdAt"]='opened on';
//setting highlighted rows

//highlighting accounts:
let highlightAll = false;
let maxValue = false;
let minValue = false;
if( (highlightMaxAccount || highlightMinAccount ) && userDataCopy){
 // console.log(`userDataCopy = ${ userDataCopy}`)
  let cleanData = JSON.parse( JSON.stringify(userDataCopy));
  console.log( 'clean data before sort: ', cleanData)
  cleanData.sort( (a,b) => {return (a.balance < b.balance) ? -1 : 1 });
  
   minValue = cleanData[0].balance;
  cleanData.reverse();
   maxValue = cleanData[0].balance;
  if( maxValue === minValue){ 
    highlightAll = true;} 
}
console.log(`maxValue = ${maxValue}    minValue = ${minValue}`)
tableFormat.row = {};
tableFormat.row.highlightAll = highlightAll;
if( highlightYourAccount) {
  tableFormat.row.loginEmail = ctx.activeUser.email;
};
if( highlightMaxAccount) {
  tableFormat.row.maxBal = maxValue;
};
if( highlightMinAccount) {
  tableFormat.row.minBal = minValue;
};




// let row = inputObject?.rowData??false;
// if(row){
//   if( row.hasOwnProperty("balance" ) && row["balance"] === "63e703d4303698cf89a3fbe8"){
//     return 'table-warning'
//   }
// }

tableFormat.tableCell = {};
// tableFormat.tableCell.classNamesFunction = (inputObject)=>{
//   let row = inputObject?.rowData??false;
//   if(row){
//     if( row.hasOwnProperty("_id" ) && row["_id"] === "63e703d4303698cf89a3fbe8"){
//       return 'text-success'
//     }
//   } 
// };

let fun1 = (inputObject) => { 
  let field = inputObject?.field??false;
  let value = inputObject?.value??false;
  if( field === 'createdAt'){
    
    
    let formatDate = inputObject?.formatDate??false;
    if(value !== false && formatDate !== false){
      return formatDate( value);
    }}
    
    return value;
    
  };
  let fun2 = (inputObject) => { 
    let field = inputObject?.field??false;
    let value = inputObject?.value??false;
    if( field === 'balance' && value !== false){
      if(value !== false && dollarFormat !== false){
        return newDollarFormat( value );
      }}
      
      return value;
      
    };
    
    
    
  tableFormat.tableCell = {};
  //tableFormat.tableCell.textFunction = (x) => {if(x.field==='balance') return dollarFormat(x.value,true,true);}
  tableFormat.tableCell.textFunction = (x) => {
    let y = fun1(x);
    x.value = y;
    return fun2(x);
  }

  function balanceCellClasses(inputObject){
    let field = inputObject.field;
    let value = inputObject.value;

    if( field === 'balance') {  
      let classAdd = " text-end";
      if( value === 0) classAdd = classAdd + ' text-warning';
      if( value <0 )classAdd = classAdd + ' text-danger';
      // if( datum < 0) classAdd = classAdd + ' table-danger';
      //if( datum < 0) classAdd = classAdd + ' table-dark';
      return classAdd;
    } 
    else return;
  }
tableFormat.tableCell.classNamesFunction = balanceCellClasses;

tableFormat.newDollarFormat = newDollarFormat;
let sortField = '';
let alphabetical = false;


switch (accountsFormatSortType) {
  case 'date':
    sortField = 'createdAt';
    break;
    case 'name':
      sortField = 'name';
      alphabetical = true;
      break;
      case 'email':
        sortField = 'email';
        alphabetical = true;
        break;
        case 'balance':
          sortField = 'balance';
    break;
  default:
    // If accountsFormatSortType is not recognized, set sortField to an empty string
    sortField = '';
  }

  let tableData = JSON.parse( JSON.stringify(userDataCopy));
  if(sortField && tableData) tableData.sort( (a,b) => {if( alphabetical ){
    return a[sortField].toLowerCase() < b[sortField].toLowerCase() ? -1 : 1}
                                         else {
    return a[sortField]< b[sortField] ? -1 : 1                                     
                                        }}
    );
  
  if(accountsFormatSortReverse & tableData) tableData.reverse();










let currencyInfo = {}
if(!(typeof countryChosen === 'object')) currencyInfo = currencyInfoByCountry(countryChosen);
let fieldArray = ["createdAt","name", "email", "password", "balance"];
console.log('tableData just prior to return: ' , tableData)
return (
  <> 
  {/* <h3>{ 'ctx.status.bankerRawExpand' + JSON.stringify(ctx.status.bankerRawExpand)}</h3> */}
    {minimizeFetchInfo ?
      <div className="d-flex align-items-end">
        <h6>{contactStatus}</h6>
        <button style={{ marginRight: '10px' }} className="btn btn-secondary" onClick={toggleMinFetch}>
          {minimizeFetchInfo ? 'more info':'minimize raw data'}
        </button>
      </div>
    :
      <div className="d-flex flex-column w-100">
        <Card
          bgcolor="light"
          otherClasses="w-100"
          txtcolor="dark"
          header={<button style={{ marginRight: '10px' }} className="btn btn-secondary" onClick={toggleMinFetch}>
          {minimizeFetchInfo ?  'more info':'minimize raw data'}
        </button>}
          title={userDataCopy || histDataCopy ? "Data currently stored at cloud.mongodb.com" : "Haven't yet connected to MongoDB"}
          maximize="true"
          text=""
          body={
            <>
              <Col xs={24} md={12} className="d-flex align-items-center">
                <>
                  <button style={{ marginRight: '10px' }} className="btn btn-primary-outline text-primary">
                  {contactStatus}
                  </button>
                  <button style={{ marginRight: '10px' }} className="btn btn-primary" onClick={getUserData}>
                    re-fetch all data from database
                  </button>
                  <button style={{ marginRight: '10px' }} className="btn btn-success-outline text-success">
                  {userDataHeader}
                  </button>
                  <button style={{ marginRight: '10px' }} className="btn btn-success" onClick={toggleUserData}>
                    {showRawUserData ? 'Hide Raw User Data' : 'Show Raw User Data'}
                  </button>
                  <button style={{ marginRight: '10px' }} className="btn btn-warning-outline text-warning">
                  {histDataHeader}
                  </button>
                  <button style={{ marginRight: '10px' }} className="btn btn-warning" onClick={toggleHistData}>
                    {showRawHistData ? 'Hide Raw History Data' : 'Show Raw History Data'}
                  </button>
                </>
              </Col>
              <br/>
              
              {showRawUserData && (
                      <>
                        <Col xs={24} md={12}>
                          <button style={{ marginRight: '10px' }} className="btn btn-success d-inline-block" onClick={toggleUserData}>hide all</button>
                          <button style={{ marginRight: '10px' }} className={"btn btn-success d-inline-block " + ( ctx.status.bankerRawExpand.join().includes('user') ? '':'disabled')}onClick={collapseAllUserRaw}>collapse all</button>
                          <h5 className="text-success d-inline-block">Start-<span style={{ fontStyle: 'italic' }}>Users Collection:</span></h5> <br/> <br/>
                        </Col>
                        <h6>{userDataCopy.map((obj, index, array) => (
                            <div key={index}>
                              {(renderTriggerUserExpand||!renderTriggerUserExpand) && ctx.status.bankerRawExpand.includes('user-'+ index) ? 
                              <button className="my-2 btn btn-outline-success text-left" onClick = { () => {
                                let newArray = ctx.status.bankerRawExpand.filter( num => num !== 'user-'+ index);
                                ctx.status.bankerRawExpand = newArray;
                                renderUserExpand()
                               }} title="click to collapse">
                                <pre>{JSON.stringify(obj, null, 2)}</pre>
                              </button> :
                               <button className="my-2 btn btn-outline-success text-left" onClick = { () => {
                                ctx.status.bankerRawExpand.push('user-'+ index);
                                renderUserExpand()
                               }} title="click to expand">
                                {JSON.stringify(obj, null, 2)}
                              </button>
                               }
                               
                              { (index + 1) % 3 === 0 && array.length - index >= 3 &&(
                                <Col xs={24} md={12}>
                                  <button style={{ marginRight: '10px' }} className="btn btn-success d-inline-block" onClick={toggleUserData}>hide all</button>
                                  <button style={{ marginRight: '10px' }} className={"btn btn-success d-inline-block " + ( ctx.status.bankerRawExpand.join().includes('user') ? '':'disabled')}onClick={collapseAllUserRaw}>collapse all</button>
                                  <h5 className="text-success d-inline-block" style={{ fontStyle: 'italic' }}>Users Collection:</h5> 
                                </Col>
                              )}
                            </div>
                          ))
                        }</h6>
                        <Col xs={24} md={12}>
                          <button style={{ marginRight: '10px' }} className="btn btn-success d-inline-block" onClick={toggleUserData}>hide all</button>
                          <button style={{ marginRight: '10px' }} className={"btn btn-success d-inline-block " + ( ctx.status.bankerRawExpand.join().includes('user') ? '':'disabled')}onClick={collapseAllUserRaw}>collapse all</button>
                          <h5 className="text-success d-inline-block">End-<span style={{ fontStyle: 'italic' }}>Users Collection:</span></h5> <br/>
                        </Col>
                      </>
                    )}

                {showRawHistData && (
                      <>
                        <Col xs={24} md={12} ref={rawhistdataRef}>
                          <button style={{ marginRight: '10px' }} className="btn btn-warning d-inline-block" onClick={toggleUserData}>hide all</button>
                          <button style={{ marginRight: '10px' }} className={"btn btn-warning d-inline-block " + ( ctx.status.bankerRawExpand.join().includes('hist')  ? '':'disabled')}onClick={collapseAllHistRaw}>collapse all</button>
                          <h5 className="text-warning d-inline-block">Start-<span style={{ fontStyle: 'italic' }}>History Collection:</span></h5> <br/> <br/>
                        </Col>
                        <h6>{histDataCopy.map((obj, index, array) => (
                          <div key={index}>
                              {(renderTriggerHistExpand||!renderTriggerHistExpand) && ctx.status.bankerRawExpand.includes('hist-'+ index) ? 
                              <button className="my-2 btn btn-warning" onClick = { () => {
                                let newArray = ctx.status.bankerRawExpand.filter( num => num !== 'hist-'+ index);
                                ctx.status.bankerRawExpand = newArray;
                                renderHistExpand()
                              }} title="click to collapse">
                                <pre>{JSON.stringify(obj, null, 2)}</pre>
                              </button> :
                               <button className="my-2 btn btn-warning text-left" onClick = { () => {
                                 ctx.status.bankerRawExpand.push('hist-'+ index);
                                 renderHistExpand()
                                }} title="click to expand">
                                {JSON.stringify(obj, null, 2)}
                              </button>
                               }
                               
                              { (index + 1) % 5 === 0 && array.length - index >= 5 &&(
                                <Col xs={24} md={12}>
                                  <button style={{ marginRight: '10px' }} className="btn btn-warning d-inline-block" onClick={toggleHistData}>hide all</button>
                                  <button style={{ marginRight: '10px' }} className={"btn btn-warning d-inline-block " + ( ctx.status.bankerRawExpand.join().includes('hist') ? '':'disabled')}onClick={collapseAllHistRaw}>collapse all</button>
                                  <h5 className="text-warning d-inline-block" style={{ fontStyle: 'italic' }}>History Collection:</h5> 
                                </Col>
                              )}
                            </div>
                          ))
                        }</h6>
                        <Col xs={24} md={12}>
                          <button style={{ marginRight: '10px' }} className="btn btn-warning d-inline-block" onClick={toggleHistData}>hide all</button>
                          <button style={{ marginRight: '10px' }} className={"btn btn-warning d-inline-block " + ( ctx.status.bankerRawExpand.join().includes('hist')  ? '':'disabled')}onClick={collapseAllHistRaw}>collapse all</button>
                          <h5 className="text-warning d-inline-block">End-<span style={{ fontStyle: 'italic' }}>History Collection:</span></h5> <br/>
                        </Col>
                      </>
                    )}
            </>
          }
        />
      </div>
    }
    { accountsFormatType === 'minimize' ?
      <button type="button" className="btn btn-secondary" onClick={ ()=>{ setAccountsFormatType('')}}><h5> Format Options</h5></button>
    :
      <>
        <h5> Format Options</h5>
        <div className="btn-group" role="group" aria-label="Account Table Formating Master Panel">
          <button type="button" className="btn btn-secondary"                                                       onClick={() => { setAccountsFormatType('minimize') }}>Minimize All Tools</button>
          <button type="button" className={`btn btn-primary ${accountsFormatType === 'sort' ? 'active' : ''}`}      onClick={() => { setAccountsFormatType('sort') }}>Sort</button>
          <button type="button" className={`btn btn-info ${accountsFormatType === 'currency' ? 'active' : ''}`}  onClick={() => { setAccountsFormatType('currency') }}>Currency</button>
          <button type="button" className={`btn btn-warning ${accountsFormatType === 'highlight' ? 'active' : ''}`} onClick={() => { setAccountsFormatType('highlight') }}>Highlight</button>
        </div>
        {(() => {
        switch (accountsFormatType) {
          case "sort":
            return (
              <>
                <h5 className="text-primary">{'Sort - click again to reverse'} </h5>
                <div className="btn-group" role="group" aria-label="Account Table Formating Sort Panel">
                  
                <button type="button" className={`btn btn-secondary ${accountsFormatSortType === '' ? 'active' : ''}`} onClick={() => { setAccountsFormatType('') }}>Hide Tool</button>

                  <button type="button" className={`btn btn-primary ${accountsFormatSortType === '' ? 'active' : ''}`}
                            onClick={() => { if(!(accountsFormatSortType === '')){ setAccountsFormatSortReverse(false) ;      setAccountsFormatSortType('') }else{setAccountsFormatSortReverse(!accountsFormatSortReverse)}}} >No Sort</button>
                  <button type="button" className={`btn btn-primary ${accountsFormatSortType === 'date' ? 'active' : ''}`}      
                            onClick={() => { if(!(accountsFormatSortType === 'date')){ setAccountsFormatSortReverse(false) ;  setAccountsFormatSortType('date') }else{setAccountsFormatSortReverse(!accountsFormatSortReverse)}}}     >Open Date</button>
                  <button type="button" className={`btn btn-primary ${accountsFormatSortType === 'name' ? 'active' : ''}`}    
                            onClick={() => { if(!(accountsFormatSortType === 'name')){ setAccountsFormatSortReverse(false) ;  setAccountsFormatSortType('name') }else{setAccountsFormatSortReverse(!accountsFormatSortReverse)}}} >Name</button>
                  <button type="button" className={`btn btn-primary ${accountsFormatSortType === 'email' ? 'active' : ''}`}    
                            onClick={() => { if(!(accountsFormatSortType === 'email')){ setAccountsFormatSortReverse(false) ; setAccountsFormatSortType('email') }else{setAccountsFormatSortReverse(!accountsFormatSortReverse)}}}>Email</button>
                  <button type="button" className={`btn btn-primary ${accountsFormatSortType === 'balance' ? 'active' : ''}`} 
                            onClick={() => { if(!(accountsFormatSortType === 'balance')){ setAccountsFormatSortReverse(false) ; setAccountsFormatSortType('balance') }else{setAccountsFormatSortReverse(!accountsFormatSortReverse)}}}>Balance</button>
                            <h4> {`accountsFormatSortType = ${ accountsFormatSortType}   accountsFormatSortReverse = ${ accountsFormatSortReverse}`}</h4>
                </div>
              </>
            );
            case "currency":
              return (
                <>
                  <h5 className="text-info" >
                    <button type="button" className={`btn btn-secondary ${accountsFormatSortType === '' ? 'active' : ''}`} onClick={() => { setAccountsFormatType('') }}>Hide Tool</button>
                    {'Currency   '}
                  </h5>
                  <CountrySelector/>
                </>
            );
          case "highlight":
             return (
              <>
                <h5 className="text-warning" >{'Highlight'}</h5>
                <div className="btn-group" role="group" aria-label="Account Table Formating Highlight Panel">
                  
                <button type="button" className={`btn btn-secondary ${accountsFormatSortType === '' ? 'active' : ''}`} onClick={() => { setAccountsFormatType('') }}>Hide Tool</button>

                  <button type="button" className={`btn btn-${(!highlightYourAccount && !highlightMaxAccount && !highlightMinAccount)? '' : 'outline-'}warning`}
                            onClick={() => { if(!(accountsFormatSortType === '')){ setHighlightYourAccount(false) ;      setHighlightMaxAccount(false) ; setHighlightMinAccount(false)}}} >No Highlight</button>
                  <button type="button" className={`btn btn-${highlightYourAccount? '' : 'outline-'}warning`}      
                            onClick={() => setHighlightYourAccount(!highlightYourAccount)}     >Your Account</button>
                  <button type="button" className={`btn btn-${highlightMaxAccount? '' : 'outline-'}warning`}   
                            onClick={() => setHighlightMaxAccount(!highlightMaxAccount)} >Max Balance</button>
                  <button type="button" className={`btn btn-${highlightMinAccount? '' : 'outline-'}warning`}  
                            onClick={() => setHighlightMinAccount(!highlightMinAccount)}>Min Balance</button>
                </div>
                {/* <h5> {`hightYourAccount = ${highlightYourAccount}         highlightMaxAccount = ${highlightMaxAccount}      highlightMinAccount = ${highlightMinAccount}`}</h5> */}
              </>
            );
          default:
            return (
              <>
                <h5>{accountsFormatType.toUpperCase()}</h5>
              </>
            );
        }
      })()}
      </>
    
   
    }
    
    <h2 className="text-center">Bad Bank Accounts</h2>
    {userDataCopy && <Table tableData={tableData} tableFormat={tableFormat} z={0} fieldArray={fieldArray} />}
  </>
);
};
