import { UserContext} from './index.js';
import {useContext, useState} from "react";
import {Card} from './card.js';
import {dollarFormat} from './dollarFormat.js';
import {timeString}   from './timeReport';
export function Balance(){
  const [ renderToggle,      setRenderToggle]        = useState(false);
  const [ showTime,      setShowTime]                = useState(false);
  const ctx = useContext(UserContext);
 let loggedIn  = ctx.status.loggedIn;
 if(loggedIn){
  if(ctx.activeUser === undefined || ctx.activeUser.balance === undefined){
    console.log('ERROR: Balance page found state loggedIn without ctx.activeUser.balance')
    loggedIn = false;
  }
 }

 function balanceInquiry(){
  let email = ctx.activeUser.email;
  console.log('email: ',email);
  let password = ctx.activeUser.password;
  console.log('password:' + password);
  // Construct URL to access the database
  let url = '/account/balance/' + email +'/' + password; 
  console.log(url); // Log the URL

// Fetch data from the URL
  (async () => {
    let res = await fetch(url);
    let dataS = await res.json();
    console.log('dataS:', dataS)
   // setServerResponse(dataS);
   ctx.activeUser.balance = dataS[2];
   ctx.userUpdateDT = new Date();
   setRenderToggle( !renderToggle);
   setShowTime(true);
   hideInThirty();
  })();
}

 function hide(){
  ctx.status.timeoutID = '';
  setShowTime(false);
 }

 function hideInThirty(){
  let toID = ctx.status.timeoutID;
  if(toID) clearTimeout(toID);
  let newtoID = setTimeout(hide, 30000);
  ctx.status.timeoutID = newtoID;
 }

 function handleShowTime(){
  hideInThirty();
  setShowTime(true);
 }

 let balance = ctx.activeUser.balance;
 let dt = ctx.userUpdateDT;
 let now = new Date();
 let timeElapsed = now - dt;

 let timeElapsedString = 'time since amount verified with Bank: ' + timeString(timeElapsed);
 
//history to be implemented
// let type = 'balanceShown';
// let data = {balance};
// ctx.history.push({userID, type, data});




  return(
    <>
    <Card
      bgcolor = "warning"
      header  = "Balance"
      status  = ''
      statusTxtColor="danger"
      body    = { !loggedIn ?(
                             <>
                              <br/>
                                Please Login to see your balance.

                              <br/>
                              <button className={"btn btn-light"}>
                              <a className="nav-link" href="#/login/">Login</a>
                              </button>

                              <button className={"btn btn-light"}>
                              <a className="nav-link" href="#/CreateAccount/">Open a New Account<span className="sr-only"></span></a>
                              </button>
                            </>):(
                            <>
                                <h5>{'Current Balance: '} 
                                  
                                  <span className={(0 > balance) ? 'text-danger' : ''}> {dollarFormat(balance)} </span>
                                </h5>
                                <br/>
                                
                                {showTime ? 
                                (<h5>
                                  {timeElapsedString}
                                </h5>):
                                (<button className={"btn btn-light"} onClick  ={handleShowTime}>
                                  Show Time Since Bank Verified Balance
                                </button> )
                                }
                                
                                <button className={"btn btn-light"} onClick  ={balanceInquiry}>
                                  Contact Bank and Verify Balance Shown
                                </button> 
                            </>
                            )
   }
   />
    </>
  )
}