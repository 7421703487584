import cc from 'currency-codes';
import getSymbolFromCurrency from 'currency-symbol-map';

//atLeastWholedigits adds zeros, until there are at least the input amount of digits to the left of the decimal
//least length adds spaces until least length reached, add space where could be set to start - before $, or end defaults to after $
//countCSLength false allows for decimal places to allign with cent symbol sticking to right
export function dollarFormat( input, 
  alwaysShowCents = true, useCommas = false, useSpaces= false , useCentSymbol = false,
  atLeastWholeDigits = null, leastLength=null, addSpaceWhere='middle', countCSLength = false, 
  dollarSymbolX = '$', centSymbolX = '\u{00A2}', negativeSymbol = '-', inputRadix = 10){
if( atLeastWholeDigits) useCentSymbol = false;
let ds = dollarSymbolX
let cs = centSymbolX;
let noDecimal = false;
let y = input.toString(inputRadix);
//remove , cent symbol (cs) and $ in case input was already a string with these
y = y.replaceAll(',','');
y = y.replaceAll(cs,'');
y = y.replaceAll(ds,'');
y = y.replaceAll('$','');
y = y.replaceAll(' ','');

if (y.charAt(0) === '-') {
    y = y.slice(1);
  } else{
    negativeSymbol = '';
  }



//removing leading zeros 
while( y.length > 1 && y[0] === '0'){
  y = y.slice(1);
}

//console.log(y);
let numSize = y.length;
//console.log(numSize);
let decLocation = y.indexOf('.');
//console.log(decLocation);
let digitAfter = numSize - decLocation -1;
//console.log(digitAfter);
let zerosNeeded = 2- digitAfter;
//console.log(zerosNeeded);
if( decLocation === -1){ 
  decLocation = numSize;
  zerosNeeded = ( alwaysShowCents ? 2 : 0);
  if(!alwaysShowCents) noDecimal = true;
  digitAfter = 0;
  }
let cents= y.slice(numSize - digitAfter);
//console.log('cents: ' + cents);
let digitsToLeft = decLocation;
let pad = '0'.repeat( zerosNeeded);
//console.log(pad);
let frontPad = (decLocation === 0) ? '0': '';
//adding cent symbol functionality
let centSymbolYes = false;
if( frontPad === '0' && useCentSymbol === true){
  centSymbolYes = true;
  frontPad = '';
  noDecimal = true;
}
let insertText = '';
if( useCommas) insertText = ',';
if( useSpaces) insertText = insertText + ' ';

//console.log(frontPad);
let wholeDigits = y.slice(0, digitsToLeft);
//console.log('wholeDigits: ' + wholeDigits); 

//adding zero pad for least whole digits parameter
if(atLeastWholeDigits !== null && atLeastWholeDigits !== Infinity){
  let wDCount = wholeDigits.length + frontPad.length;
  if( atLeastWholeDigits > wDCount){
    let zeroFillAMT = atLeastWholeDigits - wDCount;
    zeroFillAMT = Math.ceil(zeroFillAMT);
    let zeroFill = '0'.repeat(zeroFillAMT);
    wholeDigits = zeroFill + wholeDigits;
  }
}
digitsToLeft = wholeDigits.length;


//inserting commas and/or spaces
if( (insertText !== '' ) && ( digitsToLeft !== 0 ) ){
  let startingDigitNumber = digitsToLeft % 3;
  //console.log('startingDigitNumber: ' + startingDigitNumber);
  let newWholeDigits = '';
  let initial = true;
  if( startingDigitNumber === 0) startingDigitNumber = 3;
  //console.log('startingDigitNumber: ' + startingDigitNumber);
  for( let i = startingDigitNumber; i <= digitsToLeft; i += 3){
    //console.log('i: ' + i);
    let startIndex = i - 3;
    if( initial) startIndex = 0;
    let newDigits = wholeDigits.slice( startIndex, i);
    newWholeDigits = newWholeDigits + (initial ? '': insertText) + newDigits;
    initial = false;
  }
  //console.log('newWholeDigits: ' + newWholeDigits);
  wholeDigits = newWholeDigits;
}

let dollarSymbol = centSymbolYes ? ''         : ds;
let centSymbol  = centSymbolYes ? cs : '';
//console.log(answer);
let dec = noDecimal ? '':'.';
//adding space to meet leastLength parameter

let startFill = '';
let midFill   = '';
let endFill   = '';

if(leastLength !== null && leastLength !== Infinity){
  let firstanswer = negativeSymbol + dollarSymbol + frontPad + wholeDigits + dec + cents + pad;
  let answerCount = firstanswer.length;
  if(countCSLength === true) answerCount += centSymbol.length;
  if( leastLength > answerCount){
    let spaceFillAMT = leastLength - answerCount;
    spaceFillAMT = Math.ceil(spaceFillAMT);
   let spaceFill = ' '.repeat(spaceFillAMT);
   if( typeof addSpaceWhere === 'string'){
     if( addSpaceWhere === 'front' || addSpaceWhere === 'start'){
       startFill = spaceFill;
      } else {
        if( addSpaceWhere === 'end'){
          endFill = spaceFill;
        } else {
          midFill = spaceFill;
        }
      }
     } else {
     midFill = spaceFill;
      
    }
  }
 }


let answer = negativeSymbol + startFill + dollarSymbol + midFill + frontPad + wholeDigits + dec + cents + pad + centSymbol + endFill;

return answer;
}
export function currencyFormat( input,  dollarSymbolX = '$', centSymbolX = '\u{00A2}', alwaysShowCents = true, useCommas = true, useSpaces= false , useCentSymbol = false,
atLeastWholeDigits = null, leastLength=null, addSpaceWhere='middle', countCSLength = false, negativeSymbol = '-', inputRadix = 10){
  return dollarFormat( input, 
    alwaysShowCents, useCommas, useSpaces, useCentSymbol,
    atLeastWholeDigits, leastLength, addSpaceWhere, countCSLength, 
    dollarSymbolX, centSymbolX, negativeSymbol, inputRadix);
}
export function getCountryList(){
  return cc.countries();
}
// let countrylist= ["United Arab Emirates ","Afghanistan","Albania","Armenia","Curaçao","Sint Maarten (Dutch Part)","Angola","Argentina",
//     "Australia","Christmas Island","Cocos (Keeling) Islands ","Heard Island and Mcdonald Islands","Kiribati","Nauru","Norfolk Island",
//     "Tuvalu","Aruba","Azerbaijan","Bosnia and Herzegovina","Barbados","Bangladesh","Bulgaria","Bahrain","Burundi","Bermuda","Brunei Darussalam",
//     "Bolivia (Plurinational State Of)","Brazil","Bahamas ","Bhutan","Botswana","Belarus","Belize","Canada","Congo (The Democratic Republic of The)",
//     "Switzerland","Liechtenstein","Chile","China","Colombia","Costa Rica","Cuba","Cabo Verde","Czechia","Djibouti","Denmark","Faroe Islands ","Greenland",
//     "Dominican Republic ","Algeria","Egypt","Eritrea","Ethiopia","Åland Islands","Andorra","Austria","Belgium","Cyprus","Estonia","European Union","Finland",
//     "France","French Guiana","French Southern Territories ","Germany","Greece","Guadeloupe","Holy See ","Ireland","Italy","Latvia","Lithuania","Luxembourg",
//     "Malta","Martinique","Mayotte","Monaco","Montenegro","Netherlands ","Portugal","Réunion","Saint Barthélemy","Saint Martin (French Part)","Saint Pierre and Miquelon",
//     "San Marino","Slovakia","Slovenia","Spain","Fiji","Falkland Islands  [Malvinas]","Guernsey","Isle of Man","Jersey","United Kingdom of Great Britain and Northern Ireland ",
//     "Georgia","Ghana","Gibraltar","Gambia ","Guinea","Guatemala","Guyana","Hong Kong","Honduras","Croatia","Haiti","Hungary","Indonesia","Israel","India","Iraq","Iran (Islamic Republic Of)",
//     "Iceland","Jamaica","Jordan","Japan","Kenya","Kyrgyzstan","Cambodia","Comoros ","Korea (The Democratic People’s Republic Of)","Korea (The Republic Of)","Kuwait","Cayman Islands ",
//     "Kazakhstan","Lao People’s Democratic Republic ","Lebanon","Sri Lanka","Liberia","Lesotho","Libya","Morocco","Western Sahara","Moldova (The Republic Of)","Madagascar",
//     "Macedonia (The Former Yugoslav Republic Of)","Myanmar","Mongolia","Macao","Mauritania","Mauritius","Maldives","Malawi","Mexico","Malaysia","Mozambique","Namibia","Nigeria","Nicaragua",
//     "Bouvet Island","Norway","Svalbard and Jan Mayen","Nepal","Cook Islands ","New Zealand","Niue","Pitcairn","Tokelau","Oman","Panama","Peru","Papua New Guinea","Philippines ",
//     "Pakistan","Poland","Paraguay","Qatar","Romania","Serbia","Russian Federation ","Rwanda","Saudi Arabia","Solomon Islands","Seychelles","Sudan ","Sweden","Singapore",
//     "Saint Helena, Ascension and Tristan Da Cunha","Sierra Leone","Somalia","Suriname","South Sudan","Sao Tome and Principe","El Salvador","Syrian Arab Republic","Eswatini",
//     "Thailand","Tajikistan","Turkmenistan","Tunisia","Tonga","Turkey","Trinidad and Tobago","Taiwan (Province of China)","Tanzania, United Republic Of","Ukraine","Uganda",
//     "American Samoa","Bonaire, Sint Eustatius and Saba","British Indian Ocean Territory ","Ecuador","Guam","Marshall Islands ","Micronesia (Federated States Of)",
//     "Northern Mariana Islands ","Palau","Puerto Rico","Timor-Leste","Turks and Caicos Islands ","United States Minor Outlying Islands ","United States of America ",
//     "Virgin Islands (British)","Virgin Islands (U.S.)","Uruguay","Uzbekistan","Venezuela (Bolivarian Republic Of)","Viet Nam","Vanuatu","Samoa","Cameroon","Central African Republic ",
//     "Chad","Congo ","Equatorial Guinea","Gabon","Zz11_silver","Zz08_gold","Zz01_bond Markets Unit European_eurco","Zz02_bond Markets Unit European_emu-6",
//     "Zz03_bond Markets Unit European_eua-9","Zz04_bond Markets Unit European_eua-17","Anguilla","Antigua and Barbuda","Dominica","Grenada","Montserrat","Saint Kitts and Nevis",
//     "Saint Lucia","Saint Vincent and the Grenadines","International Monetary Fund (Imf) ","Benin","Burkina Faso","Côte d'Ivoire","Guinea-Bissau","Mali","Niger ","Senegal",
//     "Togo","Zz09_palladium","French Polynesia","New Caledonia","Wallis and Futuna","Zz10_platinum","Sistema Unitario De Compensacion Regional De Pagos \"Sucre\"","Zz06_testing_code",
//     "Member Countries of the African Development Bank Group","Zz07_no_currency","Yemen","South Africa","Zambia","Zimbabwe"]

export function dollarSymbolByCountry(countryString){
  if( !countryString || typeof countryString !== 'string') countryString = "United States of America ";
  let codeCountryString = cc?.country(countryString)[0]?.code??false;
  let ds='$'
  if (codeCountryString) ds = getSymbolFromCurrency(codeCountryString);
  return  ds;
}

export function currencyInfoByCountry(countryString){
  let codeCountryObj = cc.country(countryString)[0];
  let dollarSign=getSymbolFromCurrency(codeCountryObj.code);
  return  { dollarSign, ...codeCountryObj};
}
