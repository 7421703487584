export function formatDate(date) {
  if(Object.prototype.toString.call(date) !== '[object Date]'){
    date = new Date(date);
    if(Object.prototype.toString.call(date) !== '[object Date]'){
    return Error('non-date input into formatDate')}
  }
  const months = [
    "Jan.", "Feb.", "Mar.", "Apr.", "May", "Jun.",
    "Jul.", "Aug.", "Sept.", "Oct.", "Nov.", "Dec."
  ];
  
  const day = date.getDate().toString().padStart(2, "0");
  const month = months[date.getMonth()];
  const year = date.getFullYear().toString();
  
  return `${month} ${day}, ${year}`;
}

// // Example usage
// const date = new Date("2014-09-02");
// const formattedDate = formatDate(date);